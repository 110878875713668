/* eslint-disable */

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { cpf, cnpj } from "cpf-cnpj-validator";
import { useTranslation } from 'react-i18next'


import { Col, Row } from 'components/Layout'
import Alert from 'components/Alert'
import Label from 'components/Form/Label'
import Field from 'components/Form/Field'
import Input from 'components/Form/Input'
import { default as FormButton } from 'components/Form/Button'
import Button from 'components/Button'
import NotLogged from 'components/NotLogged'
import { Loading } from 'components/Icons'
import Select from 'components/Form/CustomSelect'

import { Creators as personalDataReducer } from 'store/reducers/personal-data.reducer'
import {
    getPersonalData,
    updatePersonalData,
    sendExclusionPincode,
    deletePersonalData,
} from 'store/reducers/personal-data.thunks';
import { getCountries } from 'store/reducers/geral.thunks';

import { signOut } from 'store/reducers/auth.thunks'

import './styles.sass'
import Overlay from 'components/Overlay/Overlay';

const emptyPerson = { email: "", name: "", documentType: "", document: "", phoneNumber: "", country: "" };
const YourPersonalData = props => {

    const { t } = useTranslation()
    const { auth = {}, personalData = {}, geral = {} } = useSelector((store) => store)
    const person = personalData.person || emptyPerson;
    const emptyOption = { label: '', value: '' };

    const documentTypeOptions = [
        { label: 'CPF', value: 1 },
        { label: 'CNPJ', value: 2 }
    ];

    const [exclusionMode, setExclusionMode] = useState(false);
    const [waitingPincode, setWaitingPincode] = useState(false);
    const [waitingService, setWaitingService] = useState(false);
    const [errorPincode, setErrorPincode] = useState("");
    const [pincode, setPincode] = useState("");
    const [success, setSuccess] = useState(false);
    const [errorMessages, setErrorMessages] = useState(
        {
            name: '',
            document: '',
            phoneNumber: ''
        }
    );
    const [country, setCountry] = useState(emptyOption);
    const [countries, setCountries] = useState([]);
    const [documentType, setDocumentType] = useState(documentTypeOptions[0]);
    const [disabledButtom, setDisabledButtom] = useState(false);
    const useDocument = geral.portalConfig.portalAuthType === 'AUTH_REGISTRATION_EMAIL_DOCUMENT'

    const history = useHistory();
    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch(getCountries());
        veifyForm();
    }, []);

    useEffect(() => {
        if (auth.isAuthenticated) {
            reduxDispatch(getPersonalData())
        }
        veifyForm();

    }, [reduxDispatch]);

    useEffect(() => {
        if (geral.countries != null && geral.countries.length > 0) {
            const countries = geral.countries
                .map((c) => ({
                    label: geral.language === "en" ?
                        c.nameEn
                        :
                        (geral.language === "es" ? c.nameEs : c.namePt),
                    value: c.code,
                }));
            setCountries(countries);
        }
        veifyForm();
    }, [geral.countries]);

    useEffect(() => {
        if (countries.length > 0 && personalData.person != null) {
            const selectedCountry = countries.find(c => c.value === personalData.person.country);

            if (selectedCountry !== null && selectedCountry?.value !== "") {
                setCountry(selectedCountry)
            }
        }
        veifyForm();

    }, [countries, personalData.person]);

    useEffect(() => {
        const documentTypeOpt = documentTypeOptions.find(item => item.value === personalData?.person?.documentType)
        if (documentTypeOpt) {
            const newPerson = { ...person }
            newPerson.documentType = documentTypeOpt.value;
            reduxDispatch(personalDataReducer.personalData(newPerson))
            setDocumentType(documentTypeOpt);
        }
    }, [personalData?.person?.documentType])

    const veifyForm = (documentTypeValue = documentType.value) => {
        const _phoneNumberReplace = person.phoneNumber?.replace(/([^\d])+/gim, '')
        const cpfValid = ((person.country === 'BR' || !person.country) && ((geral.portalConfig?.requiredDocument || person.document) && geral.portalConfig?.showDocument)) ?
            documentTypeValue === 1 ?
                (cpf.isValid(person.document) && person.name?.trim().length > 0) :
                (cnpj.isValid(person.document) && person.name?.trim().length > 0) : true
        const phoneNumberValid = ((geral.portalConfig?.requiredPhoneNumber || _phoneNumberReplace) && geral.portalConfig?.showPhoneNumber) ?
            !(_phoneNumberReplace?.length <= 9) : true
        const countryValid = geral.portalConfig?.showCountry ? (person.country || country) : true
        setDisabledButtom(cpfValid && phoneNumberValid && countryValid)
    }

    const submitForm = () => {
        const errors = [];
        if (!person.name.trim().length) {
            errors.push({ key: "name", value: t('yourPersonalData.validation.error.name') });
        }
        if ((person.country === 'BR' || !person.country) && geral.portalConfig?.isRequiredDocument) {
            if (!person.document.trim().length) {
                errors.push({ key: "document", value: t('yourPersonalData.validation.error.document') });
            } else if (documentType.value === 1 && !cpf.isValid(person.document)) {
                console.error('CPF INVALIDO')
                errors.push({ key: "document", value: t('yourPersonalData.validation.error.documentInvalid') });
            } else if (documentType.value === 2 && !cnpj.isValid(person.document)) {
                errors.push({ key: "document", value: t('yourPersonalData.validation.error.documentInvalid') });
                console.error('CNPJ INVALIDO')
            }
        }

        if ((!person.country || person.country === "") && geral.portalConfig?.showCountry) {
            errors.push({ key: "country", value: t('signup.validation.error.country') });
        }

        const onlyNumbersPhone = person.phoneNumber ? person.phoneNumber.replace(/[^\d]/g, "") : "";
        if (onlyNumbersPhone.length > 0 && onlyNumbersPhone.trim().length < 10) {
            errors.push({ key: "phoneNumber", value: t('yourPersonalData.validation.error.phoneNumber') });
        }

        if (errors.length > 0) {
            setErrors(errors);
        } else {
            const newData = { ...person }
            newData.phoneNumber = onlyNumbersPhone;

            reduxDispatch(
                updatePersonalData(newData, () => {
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 2000)
                })
            );
        }
    }

    const doSendExclusionPincode = () => {
        setWaitingService(true);
        reduxDispatch(
            sendExclusionPincode(geral.language, () => {
                setWaitingPincode(true);
                setWaitingService(false);
            })
        );
    }

    const submitPincodeAndDeleteData = () => {
        if (!pincode || notOnlyNumbers(pincode)) {
            setErrorPincode(true)
            return
        }
        setWaitingService(true);
        reduxDispatch(
            deletePersonalData(pincode, () => {
                setWaitingService(false);
                reduxDispatch(signOut());
            })
        );

    }

    useEffect(() => {
        // Redireciona para o login após a exclusão dos dados
        if (!auth.isAuthenticated && personalData.exclusionSuccess) {
            history.push({
                pathname: '/signin',
                successMessage: t('yourPersonalData.alert.excluded'),
            });
        }
    }, [auth.isAuthenticated, personalData.exclusionSuccess]);

    const setErrors = (errors) => {
        const newErrorMessages = { ...errorMessages };
        (errors || []).forEach(error => {
            newErrorMessages[error.key] = error.value;
        });
        setErrorMessages(newErrorMessages);
    }

    const updateField = ({ target }) => {
        const { name, value } = target;
        const newPerson = { ...person }
        newPerson[name] = value;
        setErrors([{ key: name, value: "" }]);
        reduxDispatch(personalDataReducer.personalData(newPerson))
        veifyForm();
    }

    const cancelExclusionData = () => {
        setExclusionMode(false);
        setWaitingPincode(false);
    }

    const notOnlyNumbers = (pin) => {
        var numberPattern = /^\d+$/g;
        return !pin.trim().match(numberPattern)
    }
    const onChangePincode = ({ target }) => {
        setPincode(target.value)
        setErrorPincode(notOnlyNumbers(target.value));
    }

    function handleCountry(val) {
        const name = 'country';
        const { value } = val;

        const newPerson = { ...person }
        newPerson[name] = value;


        setErrors([{ key: name, value: "" }]);
        reduxDispatch(personalDataReducer.personalData(newPerson));
        setCountry(val);
    }

    function handleTypeDocument(val) {
        const { value } = val
        setDocumentType(documentTypeOptions.find(item => item.value === value))
        veifyForm(value)
    }

    if (!auth.isAuthenticated && !exclusionMode) {
        return (
            <section>
                {/* <header className="pvt-content-header custom">
                    <h1 className="pvt-content-title custom">{t('yourPersonalData.title')}</h1>
                </header> */}
                <NotLogged />
            </section>
        );
    }

    if (exclusionMode) {
        return (
            <section>
                <header className="pvt-content-header custom">
                    <h1 className="pvt-content-title custom">{t('yourPersonalData.exclusion.title')}</h1>
                </header>
                {waitingPincode ?
                    <div className="dsar-form">
                        <p className="pvt-content-desc custom">
                            <b>{t('yourPersonalData.exclusion.content.description')}</b>
                        </p>
                        <div className="pvt-magical-space">
                            <Field>
                                <Label label={t('yourPersonalData.exclusion.label.code')} relation="pin-field" />
                                <Input id="pin-field" value={pincode} onChange={onChangePincode}
                                    invalidMessage={errorPincode && pincode ? t('yourPersonalData.exclusion.validation.code') : null} />
                            </Field>
                        </div>
                        <Row position={['y-start']}>
                            <Col>
                                <Button
                                    type="cancel"
                                    size="small"
                                    label={t('button.label.cancel')}
                                    onClick={cancelExclusionData}
                                />
                            </Col>
                            <Col>&nbsp;</Col>
                            <Col>
                                {waitingService && !personalData.error ?
                                    <Loading />
                                    :
                                    <Button
                                        type="danger"
                                        size="small"
                                        label={t('yourPersonalData.exclusion.button.send')}
                                        onClick={submitPincodeAndDeleteData}
                                    />
                                }
                            </Col>
                        </Row>
                        <FeedbackMessages success={success} error={personalData.error} />
                    </div>
                    :
                    <div className="dsar-form">
                        <p className="pvt-content-desc custom">
                            {t('yourPersonalData.exclusion.confirm.description')}
                        </p>

                        <div className="pvt-magical-space">
                            <Row position={['y-start']}>
                                <Col>
                                    <Button
                                        type="cancel"
                                        size="small"
                                        label={t('button.label.cancel')}
                                        onClick={cancelExclusionData}
                                    />
                                </Col>
                                <Col>&nbsp;</Col>
                                <Col>
                                    {waitingService && !personalData.error ?
                                        <Loading />
                                        :
                                        <Button
                                            type="danger"
                                            size="small"
                                            label={t('yourPersonalData.exclusion.button.confirm')}
                                            onClick={doSendExclusionPincode}
                                        />
                                    }
                                </Col>
                            </Row>
                            <FeedbackMessages success={success} error={personalData.error} />
                        </div>
                    </div>
                }
            </section>
        );
    }

    return (
        <section>
            <header className="pvt-content-header custom">
                <h1 className="pvt-content-title custom">{t('yourPersonalData.title')}</h1>
                {/* <p className="pvt-content-desc custom">
                    {t('yourPersonalData.content.description')}
                </p> */}
                {geral.portalConfig?.exclusionAllowed ?
                    <p className="pvt-magical-space">
                        <Button
                            type="danger"
                            size="small"
                            label={t('yourPersonalData.button.delete')}
                            onClick={() => setExclusionMode(true)}
                        />
                    </p>
                    : null
                }
            </header>
            <div className="pvt-card pvt-card-100">
                <div className="dsar-form">

                    {personalData.fetching ?
                        <Field>
                            <p><Loading /> {t('yourPersonalData.loading')}</p>
                        </Field>
                        : null
                    }

                    <FeedbackMessages
                        success={success}
                        error={personalData.error} t={t}
                    />
                    {geral.portalConfig?.showCountry &&
                        <Field>
                            <Label
                                label={t('yourRequests.label.country')}
                                mandatory={false}
                            />
                            <Select
                                placeholder=""
                                onChange={handleCountry}
                                isSearchable={false}
                                value={country}
                                options={countries}
                            />
                        </Field>
                    }
                    <Field>
                        <Label label={t('yourPersonalData.label.email')} relation="email-field" />
                        <Input id="email-field" type="email"
                            name="email"
                            value={person.email}
                            disabled={true} />
                    </Field>

                    <Field>
                        <Label label={t('yourPersonalData.label.name')} relation="name-field" mandatory={true} />
                        <Input
                            id="name-field"
                            type="text"
                            name="name"
                            value={person.name}
                            onChange={updateField}
                            invalidMessage={errorMessages.name}
                            maxLength={50}
                        />
                    </Field>

                    {
                        (country?.value === 'BR' || !country?.value) && geral.portalConfig?.showDocument
                        &&
                        <>
                            <Field>
                                <Label label={t('yourPersonalData.label.documentType')} mandatory={true} />
                                <Select
                                    name="typeDocument"
                                    placeholder=""
                                    onChange={handleTypeDocument}
                                    isSearchable={false}
                                    value={documentType}
                                    options={documentTypeOptions}
                                />
                            </Field>
                            <Field>
                                <Label label={documentType.label} relation="document-field" mandatory={geral.portalConfig?.requiredDocument} />
                                <Input id="document-field" type="number"
                                    name="document"
                                    value={person.document}
                                    onChange={updateField}
                                    invalidMessage={errorMessages.document}
                                    maxLength={documentType.value === 1 ? 11 : 14}
                                    disabled={useDocument}
                                />
                            </Field>
                        </>
                    }
                    {
                        geral.portalConfig?.showPhoneNumber ?
                            <Field>
                                <Label label={t('yourPersonalData.label.phoneNumber')} mandatory={geral.portalConfig?.requiredPhoneNumber} relation="phone-number-field" />
                                <Input mask="(99)99999-9999" id="phone-number-field" type="tel"
                                    name="phoneNumber"
                                    value={person.phoneNumber}
                                    onChange={updateField}
                                    invalidMessage={errorMessages.phoneNumber}
                                />
                            </Field>
                            : null
                    }

                    <FormButton
                        className="pvt-btn custom pvt-btn-confirm full size-undefined"
                        loading={personalData.updating}
                        label={t('yourPersonalData.button.update')}
                        onClick={submitForm}
                        disabled={disabledButtom}
                    />

                    <Overlay visible={personalData.updating} />

                </div>
            </div>

        </section>
    );
}

const FeedbackMessages = ({ success, error, t }) => {

    return (
        <div className="pvt-msg-space">
            {success ?
                <Field>
                    <Alert
                        align="center"
                        size="full"
                        type="success"
                        text={
                            <>
                                <b>{t('yourPersonalData.message.success')}</b>
                            </>
                        }
                    />
                </Field>
                : null
            }
            {error ?
                <Field>
                    <Alert
                        align="center"
                        size="full"
                        type="error"
                        text={
                            <>
                                <b>{t('yourPersonalData.message.error')}</b>
                            </>
                        }
                    />
                </Field>
                : null
            }

        </div>
    );
}

export default YourPersonalData;

/* eslint-disable */